import React from 'react';

function NavBar({ selectedPage }) {
    const pages = [
        { name: 'Home', href: '/' },
        { name: 'Feedback', href: '#feedback' },
        { name: 'Privacy Policy', href: 'privacyPolicies' },
        { name: 'Support', href: '#support' },
    ];

    return (
        <div className="sticky top-0 z-10 w-64 bg-[#FD0000] text-white min-h-screen max-h-screen">
            <ul className="space-y-4 p-4">
                {pages.map((page, index) => (
                    <li
                        key={index}
                        className={`hover:text-gray-300 ${selectedPage === index ? 'font-bold' : ''
                            }`}
                    >
                        <a href={page.href}>{page.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default NavBar;
